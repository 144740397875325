$family: 'Sarabun';
$font-type: (
  'normal': (
    'extralight': (
      'weight': 200,
      'src': url(./Sarabun/Sarabun-ExtraLight.ttf),
    ),
    'light': (
      'weight': 300,
      'src': url(./Sarabun/Sarabun-Light.ttf),
    ),
    'regular': (
      'weight': 400,
      'src': url(./Sarabun/Sarabun-Regular.ttf),
    ),
    'medium': (
      'weight': 500,
      'src': url(./Sarabun/Sarabun-Medium.ttf),
    ),
    'semibold': (
      'weight': 600,
      'src': url(./Sarabun/Sarabun-SemiBold.ttf),
    ),
    'bold': (
      'weight': 700,
      'src': url(./Sarabun/Sarabun-Bold.ttf),
    ),
    'extrabold': (
      'weight': 800,
      'src': url(./Sarabun/Sarabun-SemiBold.ttf),
    ),
    'xextrabold': (
      'weight': 900,
      'src': url(./Sarabun/Sarabun-SemiBold.ttf),
    ),
  ),
);

@each $type, $font-properties in $font-type {
  @each $weight, $weight-properties in $font-properties {
    @font-face {
      font-family: $family;
      font-style: $type;
      font-weight: map-get($weight-properties, 'weight');
      src: map-get($weight-properties, 'src') format('truetype');
    }
  }
}
